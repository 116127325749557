import { Component, inject, signal } from '@angular/core';
import { SendService } from '../../../../services/send.service';
import { UserService } from '@app/services/user.service';
import { SharedService } from '@app/services/shared.service';
import { AnalyticsService } from 'libs/shared/src/lib/services/analytics.service';
import { AiService } from '@app/services/ai.service';
import { BluApiService } from '@app/services/blu-api.service';
import {
  ToastMessage,
  ToastService,
} from 'libs/shared/src/lib/services/toast.service';

@Component({
  selector: 'blu-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent {
  copiedVariable?: string;
  formattingText = false;
  formattedText = signal('');

  sharedService = inject(SharedService);
  sendService = inject(SendService);
  userService = inject(UserService);
  analyticsService = inject(AnalyticsService);
  AIService = inject(AiService);
  bluApiService = inject(BluApiService);
  private toastService = inject(ToastService);

  setValue(tar: any): void {
    this.sendService.content.text = tar.value;
  }

  setPollName(tar: EventTarget | null): void {
    let value: string | undefined = (tar as HTMLInputElement).value;
    if (value === '') value = undefined;
    this.sendService.content.poll.name = value || null;
  }

  copyTextToClipboard(text: string): void {
    navigator.clipboard.writeText(`{{${text}}}`);
  }

  async formatText() {
    this.formattingText = true;
    if (!this.sendService.content.text) return;
    const formattedText = await this.AIService.formatText(
      this.sendService.content.text,
    );
    this.sendService.content.text = formattedText;
    this.formattedText.set(formattedText);
    this.formattingText = false;
  }

  async previewCampaign() {
    try {
      await this.bluApiService.previewCampaign(this.sendService.content);
      this.toastService.createToast({
        title: 'Teste concluído',
        message: ToastMessage.PreviewCampaignSuccess,
        icon: 'check_circle',
        iconColor: 'rgb(var(--functional-green))',
      });
    } catch (error) {
      this.toastService.createToast({
        title: 'Erro',
        message: ToastMessage.PreviewCampaignError,
        icon: 'error',
        iconColor: 'rgb(var(--functional-red))',
      });
    }
  }
}
